var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form-item',{attrs:{"label":_vm.schema.label,"label-width":_vm._f("nil")(_vm.schema.labelWidth,''),"rules":_vm._f("nil")(_vm.schema.rules,[]),"prop":_vm.schema.prop + '.selected'}},[_c('div',[_vm._v(" "+_vm._s(_vm.schema.allText)+" ")]),_c('div',{staticStyle:{"margin":"15px 0"}}),_c('el-radio-group',{staticStyle:{"line-height":"40px"},attrs:{"value":_vm.$options.filters.nil(_vm.value, {}).selected}},_vm._l((_vm.schema.opts),function(opt){return _c('el-radio',{key:opt.value,attrs:{"label":opt.value},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onRadioClick(opt.value)}}},[_vm._v(_vm._s(opt.label))])}),1)],1),_c('div',{staticStyle:{"margin":"15px 0"}}),(_vm.$options.filters.nil(_vm.value, {}).selected)?_c('el-form-item',{attrs:{"label":"选择有效期","label-width":_vm._f("nil")(_vm.schema.labelWidth,''),"prop":_vm.schema.prop + '.range',"rules":[{ required: true, message: '请选择有效期', trigger: 'change' }]}},[_c('q-date-range-picker',{attrs:{"value":_vm.$options.filters.nil(_vm.value, {}).range},on:{"input":function (val) {
            _vm.$emit('input', Object.assign({}, _vm.value, {range: val}));
          }}})],1):_vm._e(),(_vm.$options.filters.nil(_vm.value, {}).selected)?_c('el-form-item',{attrs:{"label":"应用展示图片","prop":_vm.schema.prop + '.logo'}},[_c('el-row',[_c('el-col',{attrs:{"span":4}}),_c('el-col',{staticClass:"customer",staticStyle:{"position":"relative"},attrs:{"span":20}},[_c('q-image-upload',{attrs:{"schema":{
								xType: 'q-field',
								fType: 'q-image-upload',
						size: 50,
							},"value":_vm.$options.filters.nil(_vm.value, {}).logo},on:{"input":function (val) {
            _vm.$emit('input', Object.assign({}, _vm.value, {logo: val}));
          }}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }