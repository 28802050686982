<template>
  <q-page
    @update:schema="
      (sval, stype, spath) => {
        if (stype === 'dialog:visible') {
          this.dg.visible = sval.visible;
        }
      }
    "
    path="page"
    :schema="{
      id: `${schema.subjectKey}-page`,
      xType: 'q-page',
      title: `${schema.subjectName}管理`,
      items: [
      //新增操作
        {
          id: `${schema.subjectKey}-tool-bar`,
          xType: 'q-tool-bar',
          items: [
            {
              xType: 'q-row',
              type: 'flex',
              justify: 'end',
              items: [
                {
                  xType: 'q-button',
                  visible: $options.filters.nil(schema.enableAdd, true),
                  auth:schema.addAuth,
                  type: 'primary',
                  text: `+ 新增${schema.subjectName}`,
                  click: schema.add ? schema.add : add,
                },
              ],
            },
          ],
        },
      // 搜索操作
        {
          id: `${schema.subjectKey}-search-bar`,
          visible: $options.filters.nil(schema.searchFields, []).length > 0,
          xType: 'q-search-bar',
          items: [
            {
              xType: 'q-row',
              type: 'flex',
              justify: 'start',
              align: 'middle',
              items: [
                {
                  xType: 'q-col',
                  span: 23,
                  items: [
                    {
                      id: `${schema.subjectKey}-search-form`,
                      xType: 'q-form',
                      inline: true,
                      items: [...schema.searchFields],
                    },
                  ],
                },
                {
                  xType: 'q-col',
                  span: 1,
                  items: [
                    {
                      xType: 'q-button',
                      text: '查询',
                      click: search,
                    },
                  ],
                },
              ],
            },
          ],
        },
        //列表
        {
          id: `${schema.subjectKey}-data-grid`,
          xType: 'q-data-grid',
          pagingEnable: $options.filters.nil(schema.pagingEnable, true),
          getPageList: getPageList,
          getList: getList,
          table: {
            id: `${schema.subjectKey}-data-grid-table`,
            xType: 'table',
            width: '100%',
            columns: [...schema.gridColumns],
          },
        },
        {
          xType: 'q-dialog',
          visible: this.dg.visible,
          title: this.dg.title,
          width: schema.dialogWidth,
          items: [
            {
              id: `q-${schema.subjectKey}-form`,
              labelWidth: '150px',
              xType: 'q-form',
              items: [...schema.formFields],
            },
          ],
          buttons: [
            {
              xType: 'button',
              text: '取消',
            },
            {
              xType: 'button',
              text: '确定',
              click: save,
            },
          ],
        },
      ],
    }"
  />
</template>

<script>
import BizCode from "@/utils/BizCode";
import { findComponentById } from "@/utils/index";
import _ from "lodash";

export default {
  name: "QCrud",
  props: {
    schema: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dg: {
        title: "",
        visible: false,
      },
    };
  },
  created(){
  
  },
  methods: {
    getApi() {
      return this.schema.api;
    },
    //返回的分页列表数据
    getPageList(...p) {
      const api = this.getApi();
      console.log(api.getPageList(...p),"getPageList");
      return api.getPageList(...p);
    },
    // 返回的列表数据
    getList(...p) {
      const api = this.getApi();
      if (api?.getList) {
        console.log(api.getList(...p),"getList");
        return api.getList(...p);
      }
      return [];
    },
    add() {
      this.dg.title = `新增${this.schema.subjectName}`;
      this.dg.visible = true;
      //导致form动态挂载
      //动态挂在上的form此时尚未完全渲染完毕所以需要等到其全部渲染完毕才可以拿this.$refs.frm
      this.$nextTick(() => {
        const frm = findComponentById(`q-${this.schema.subjectKey}-form`, this);
        frm.setData((data) => {
          data.mode = "add";
        });
      });
    },
    update(event, row) {
      this.dg.title = `修改${this.schema.subjectName}`;
      this.dg.visible = true;
      this.$nextTick(() => {
        const frm = findComponentById(`q-${this.schema.subjectKey}-form`, this);
        frm.setData((data) => {
          data.mode = "update";
          data.id = row.id;
        });
        this.$nextTick(async () => {
          const api = this.getApi();
          const loadData = this.schema?.handlers?.loadData;
          if (loadData) {
            await loadData(frm, row);
          } else {
            const result = await api.detail(row.id);
            if (result?.code === BizCode.OK) {
              const { data } = result;
              frm.setData({
                ...frm.getData(),
                ...data,
              });
            }
          }
        });
      });
    },
    async save() {
      this.$nextTick(async () => {
        const frm = findComponentById(`q-${this.schema.subjectKey}-form`, this);
        console.log(frm,"QCrud-frm");
        frm.validate(async (valid) => {
          //验证成功
          if (valid) {
            const data = frm.getData();
            console.log(data,'QCrud-data');
            const { id, mode, ...fields } = data;
            let result = null;
            const api = this.getApi();
            const saveData = this.schema?.handlers?.saveData;
            if (saveData) {
              result = await saveData(frm, mode, id, fields);
              console.log(result,'QCrud-result');
            } else {
              if (mode === "add") {
                result = await api.add(fields);
              } else {
                result = await api.update(id, fields);
              }
            }
            this.dg.title = "";
            this.dg.visible = false;
            frm.setData({});
            const grid = findComponentById(
              `${this.schema.subjectKey}-data-grid`,
              this
            );
            grid.syncServer();
          }
        });
      });
    },
    syncServer() {
      const grid = findComponentById(
        `${this.schema.subjectKey}-data-grid`,
        this
      );
      grid.syncServer();
    },
    search() {
      const searchFrm = findComponentById(
        `${this.schema.subjectKey}-search-form`,
        this
      );
      searchFrm.validate((valid) => {
        if (valid) {
          const grid = findComponentById(
            `${this.schema.subjectKey}-data-grid`,
            this
          );
          grid.currentPage = 1;
          grid.setQueryParams(_.cloneDeep(searchFrm.getData()));
          // grid.setQueryParams(searchFrm.getData());
          // grid.syncServer();
        }
      });
    },
  },
  //还原query参数
  watch: {
    "$route.query": {
      handler(p) {
        let queryParams = {};
        if (p?.queryParams !== null && p?.queryParams !== undefined) {
          queryParams = JSON.parse(window.decodeURI(p.queryParams));
        } else {
          queryParams = {};
        }

        this.$nextTick(() => {
          const searchFrm = findComponentById(
            `${this.schema.subjectKey}-search-form`,
            this
          );
          if (searchFrm !== null && searchFrm !== undefined) {
            searchFrm.setData({ ...queryParams });
          }
        });

        //this.setOrderParams(p.orderParams ?? {});
        //this.fetchData();
        //this.syncServer(); //获取数据
      },
      immediate: true, //立即执行一次需要
    },
  },
};
</script>
