import pagination from './index.vue'


const Pagination = {
  install: function (Vue) {
    Vue.component('Pagination', pagination)
  }
}
  

export default Pagination