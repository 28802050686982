var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.schema.hidden !== true),expression:"schema.hidden !== true"}],ref:"formItem",attrs:{"label":_vm.schema.label,"label-width":_vm._f("nil")(_vm.schema.labelWidth,''),"prop":_vm.schema.prop,"rules":[
    {
      required: _vm.$options.filters._location_has_value(
        _vm.schema.formData['location']
      ),
      message: '请输入完整的地址',
      trigger: 'change',
    } ]}},[_c('el-input',{attrs:{"type":"textarea","maxlength":50,"rows":4,"placeholder":this.dis ? '' : _vm.schema.placeholder,"disabled":this.dis,"value":_vm.value},on:{"input":function (val) { return _vm.$emit('input', val); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }