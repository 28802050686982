import Vue from "vue";

//filter
import "@/utils/filters";
//method

import ElementUI from "element-ui";
import "@/assets/style/theme/theme/index.css";

import App from "./App.vue";
import router from "./router";

// eslint-disable-next-line no-unused-vars
import { enhanceRouter } from "./permission.js";

//Vuex
import store from "./store";

//低代码平台组件引入
import QEntry from "@/components/QEntry";

import Fragment from "vue-fragment";

// eslint-disable-next-line no-unused-vars
import AuthClient from "@/utils/AuthClient";
import echarts from 'echarts' //引入echarts
import scroll from 'vue-seamless-scroll'


import "./font/icon/m2/iconfont.css";
//reset the style
import "normalize.css/normalize.css";
import "./assets/style/index.scss";
import Pagination from '@/components/pagination/index'

Vue.config.productionTip = false;

// eslint-disable-next-line no-irregular-whitespace
Vue.use(ElementUI, { size: 'small' });

Vue.use(Fragment.Plugin);

import './directives/auth';

//导入后退键
Vue.prototype.$echarts = echarts
Vue.prototype.$back = () => window.history.back()
enhanceRouter(router);

//低代码平台组件引入
Vue.use(scroll)
Vue.use(QEntry);

Vue.use(Pagination)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

new AuthClient().startChecking();
