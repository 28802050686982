//import request from "@/utils/request";
import { request } from "@/utils/request";
import { loading } from "@/utils/index";

const BASE_PATH = "/corpUser";

//内部用户
class Api {
  //新增
  @loading()
  async add(form) {
    return request.post(`${BASE_PATH}`, form);
  }

  //修改
  @loading()
  async update(id, form) {
    return request.put(`${BASE_PATH}/${id}`, form);
  }

  /** 内部用户详情 */
  @loading({ delay: 0 })
  async detail(id) {
    return request.get(`${BASE_PATH}/${id}`);
  }

  /** 禁用 */
  @loading({ delay: 0 })
  async enable(id, disabled) {
    return request.put(`${BASE_PATH}/${id}/enable?disabled=${disabled}`);
  }

  /** 删除内部用户 */
  @loading()
  async del(id) {
    return request.delete(`${BASE_PATH}/${id}`);
  }

  /** 重置密码 */
  @loading()
  async reset(id) {
    return request.put(`${BASE_PATH}/${id}/resetPassword`);
  }

  /** 分页获取内部用户列表 */
  @loading({ delay: 0 })
  async getPageList(params) {
    return request.get(`${BASE_PATH}/page`, { params: params });
  }

  //修改密码
  @loading()
  async updatePwd(form) {
    return request.put(`${BASE_PATH}/updatePwd`, form);
  }

  //获取自身信息
  @loading()
  async self() {
    return request.get(`${BASE_PATH}/self`);
  }
}

export default new Api();
