import Vue from "vue";
import _ from "lodash";
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

Vue.filter("nil", (value, ret = "") => {
  return value ?? ret;
});

Vue.filter("_path", (value, path, ret = "") => {
  return _.get(value, path, ret);
});

Vue.filter("_frm_data", (value) => {
  return value !== null && value !== undefined ? { formData: value } : {};
});

Vue.filter("_date_time", (value) => {
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
});

Vue.filter("_date", (value) => {
  return moment(value).format('YYYY-MM-DD')
});


// eslint-disable-next-line no-unused-vars
Vue.filter("_uuid", (value) => {
  return uuidv4();
});


Vue.filter("_location_has_value", (value) => {
  if (value === undefined || value === null) {
    return false;
  }
  if (value.length === 0) {
    return false;
  }
  if (
    (value[0] === null || value[0] === undefined || value[0] === "") &&
    (value[1] === null || value[1] === undefined || value[1] === "") &&
    (value[2] === null || value[2] === undefined || value[2] === "")
  ) {
    return false;
  }
  return true;
});
