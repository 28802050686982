<template>
  <component
    :index="schema.id"
    :is="schema.xType === 'subMenu' ? 'el-submenu' : 'el-menu-item'"
    v-if="authMenu(schema.ROLE, roles) && schema.hidden"
  >
    <template slot="title">
      <router-link :to="schema.link" v-if="schema.link" slot="title">
        <i :class="schema.icon"></i>
        {{ schema.title }}
      </router-link>
      <span v-else slot="title">
        <i :class="schema.icon"></i>
        {{ schema.title }}
      </span>
    </template>

    <!-- recursion components -->
    <menu-bar-item
      :key="item.id"
      :schema="item"
      v-for="item in schema.items"
      :roles="roles"
    >
    </menu-bar-item>
  </component>
</template>

<script>
export default {
  name: "MenuBarItem",
  props: {
    schema: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  created() {},
  methods: {
    authMenu(menuRoles, serverRoles) {
      return menuRoles.some((m) => serverRoles.includes(m));
    },
  },
};
</script>
