var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{attrs:{"path":"page","schema":{
    id: ((_vm.schema.subjectKey) + "-page"),
    xType: 'q-page',
    title: ((_vm.schema.subjectName) + "管理"),
    items: [
    //新增操作
      {
        id: ((_vm.schema.subjectKey) + "-tool-bar"),
        xType: 'q-tool-bar',
        items: [
          {
            xType: 'q-row',
            type: 'flex',
            justify: 'end',
            items: [
              {
                xType: 'q-button',
                visible: _vm.$options.filters.nil(_vm.schema.enableAdd, true),
                auth:_vm.schema.addAuth,
                type: 'primary',
                text: ("+ 新增" + (_vm.schema.subjectName)),
                click: _vm.schema.add ? _vm.schema.add : _vm.add,
              } ],
          } ],
      },
    // 搜索操作
      {
        id: ((_vm.schema.subjectKey) + "-search-bar"),
        visible: _vm.$options.filters.nil(_vm.schema.searchFields, []).length > 0,
        xType: 'q-search-bar',
        items: [
          {
            xType: 'q-row',
            type: 'flex',
            justify: 'start',
            align: 'middle',
            items: [
              {
                xType: 'q-col',
                span: 23,
                items: [
                  {
                    id: ((_vm.schema.subjectKey) + "-search-form"),
                    xType: 'q-form',
                    inline: true,
                    items: [].concat( _vm.schema.searchFields ),
                  } ],
              },
              {
                xType: 'q-col',
                span: 1,
                items: [
                  {
                    xType: 'q-button',
                    text: '查询',
                    click: _vm.search,
                  } ],
              } ],
          } ],
      },
      //列表
      {
        id: ((_vm.schema.subjectKey) + "-data-grid"),
        xType: 'q-data-grid',
        pagingEnable: _vm.$options.filters.nil(_vm.schema.pagingEnable, true),
        getPageList: _vm.getPageList,
        getList: _vm.getList,
        table: {
          id: ((_vm.schema.subjectKey) + "-data-grid-table"),
          xType: 'table',
          width: '100%',
          columns: [].concat( _vm.schema.gridColumns ),
        },
      },
      {
        xType: 'q-dialog',
        visible: this.dg.visible,
        title: this.dg.title,
        width: _vm.schema.dialogWidth,
        items: [
          {
            id: ("q-" + (_vm.schema.subjectKey) + "-form"),
            labelWidth: '150px',
            xType: 'q-form',
            items: [].concat( _vm.schema.formFields ),
          } ],
        buttons: [
          {
            xType: 'button',
            text: '取消',
          },
          {
            xType: 'button',
            text: '确定',
            click: _vm.save,
          } ],
      } ],
  }},on:{"update:schema":function (sval, stype, spath) {
      if (stype === 'dialog:visible') {
        this$1.dg.visible = sval.visible;
      }
    }}})}
var staticRenderFns = []

export { render, staticRenderFns }