<template>
  <el-form-item
    ref="formItem"
    v-show="schema.hidden !== true"
    :label="schema.label"
    :label-width="schema.labelWidth | nil('')"
    :prop="schema.prop"
    :rules="[
      {
        required: $options.filters._location_has_value(
          schema.formData['location']
        ),
        message: '请输入完整的地址',
        trigger: 'change',
      },
    ]"
  >
    <el-input
      type="textarea"
      :maxlength="50"
      :rows="4"
      :placeholder="this.dis ? '' : schema.placeholder"
      :disabled="this.dis"
      :value="value"
      @input="(val) => $emit('input', val)"
    ></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: "QAddress",
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  inject: ["elForm"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    dis() {
      return (
        this.elForm.disabled ||
        !this.$options.filters._location_has_value(
          this.schema.formData["location"]
        )
      );
    },
  },
};
</script>
