<template>
  <fragment>
    <el-checkbox-group
      :value="value | nil([])"
      @input="
        (val) => {
          $emit('input', val);
        }
      "
    >
      <el-checkbox v-for="opt in options" :key="opt.value" :label="opt.value">
        {{ opt.label }}
      </el-checkbox>
    </el-checkbox-group>
  </fragment>
</template>

<script>
export default {
  name: "QCheckBoxGroup",
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      loading: false,
      options: this.schema.opts ?? [],
    };
  },
  methods: {},
  watch: {
    // value: {
    //   immediate: true,
    //   // eslint-disable-next-line no-unused-vars
    //   handler(val, oldVal) {
    //     if (val !== oldVal) {
    //       if (this.schema.onValueChange) {
    //         this.schema.onValueChange(val);
    //       }
    //     }
    //   },
    // },
    "schema.opts": {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.options = [...val];
        }
      },
    },
    "schema.loading": {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.loading = val;
        }
      },
    },
  },
};
</script>
