import DelayLoadingService from "@/components/LoadingService/DelayLoadingService";

export const MenuJson = [
  {
    id: "0",
    xType: "menuItem",
    title: "首页",
    hidden: true,
    active: '0',
    icon: "el-icon-fix-width iconfont iconshouye1", //fix 图表宽度的bug
    link: "/show",
    ROLE: ["ROLE", "CORP_USER", "APPLICATION", "VERSION","CUSTOMER"],
  },
  {
    id: "1",
    xType: "subMenu",
    title: "系统设置",
    hidden: true,
    icon: "el-icon-s-tools",
    ROLE: ["ROLE", "CORP_USER"],
    items: [
      {
        id: "1-1",
        xType: "menuItem",
        title: "角色管理",
        active: '1-1',
        hidden: true,
        link: "/role",
        ROLE: ["ROLE"],
      },
      {
        id: "1-1-1",
        xType: "menuItem",
        title: "设置权限",
        hidden: false,
        active: '1-1',
        link: "/authority",
        ROLE: ["ROLE"],
      },
      {
        id: "1-2",
        xType: "menuItem",
        title: "内部用户管理",
        active: '1-2',
        hidden: true,
        link: "/staff",
        ROLE: ["CORP_USER"],
      },
      {
        id: "1-2-1",
        xType: "menuItem",
        title: "添加内部用户管理，编辑内部用户管理，查看内部用户管理",
        active: '1-2',
        hidden: false,
        link: "/editStaff",
        ROLE: ["CORP_USER"],
      },
    ],
  },
  {
    id: "2",
    xType: "menuItem",
    title: "应用管理",
    hidden: true,
    active: '2',
    icon: "el-icon-fix-width iconfont iconziliao", //fix 图表宽度的bug
    link: "/application",
    ROLE: ["APPLICATION"],
  },
  {
    id: "2-1",
    xType: "menuItem",
    title: "添加应用管理，编辑应用管理，查看应用管理",
    hidden: false,
    active: '2',
    icon: "el-icon-fix-width iconfont iconziliao", //fix 图表宽度的bug
    link: "/editApplication",
    ROLE: ["APPLICATION"],
  },
  {
    id: "2-2",
    xType: "menuItem",
    title: "菜单管理",
    hidden: false,
    active: '2',
    icon: "el-icon-fix-width iconfont iconziliao", //fix 图表宽度的bug
    link: "/resource",
    ROLE: ["APPLICATION"],
  },
  //应用管理-->模板管理
  {
    id: "2-3",
    xType: "menuItem",
    title: "模板管理",
    hidden: false,
    active: '2',
    icon: "el-icon-fix-width iconfont iconziliao", //fix 图表宽度的bug
    link: "/templateManage",
    ROLE: ["APPLICATION"],
  },
  //模板编辑
  {
    id: "2-4",
    xType: "menuItem",
    title: "添加模板管理，编辑模板管理，查看模板管理",
    hidden: false,
    active: '2',
    icon: "el-icon-fix-width iconfont iconziliao", //fix 图表宽度的bug
    link: "/templateEdit",
    ROLE: ["APPLICATION"],
  },
  //数据配置
  {
    id: "2-5",
    xType: "menuItem",
    title: "添加模板管理，编辑模板管理，查看模板管理",
    hidden: false,
    active: '2',
    icon: "el-icon-fix-width iconfont iconziliao", //fix 图表宽度的bug
    link: "/templateDataConfig",
    ROLE: ["APPLICATION"],
  },
  {
    id: "3",
    xType: "menuItem",
    title: "版本管理",
    hidden: true,
    active: '3',
    icon: "el-icon-location",
    link: "/version",
    ROLE: ["VERSION"],
  },
  {
    id: "3-1",
    xType: "menuItem",
    title: "添加版本，编辑版本，查看版本",
    hidden: false,
    active: '3',
    icon: "el-icon-location",
    link: "/editVersion",
    ROLE: ["VERSION"],
  },
  {
    id: "4",
    xType: "menuItem",
    title: "客户管理",
    hidden: true,
    active: '4',
    icon: "el-icon-office-building",
    link: "/customer",
    ROLE: ["CUSTOMER"],
  },
  {
    id: "4-1",
    xType: "menuItem",
    title: "添加客户管理，编辑客户管理，查看客户管理",
    hidden: false,
    active: '4',
    icon: "el-icon-office-building",
    link: "/editCustomer",
    ROLE: ["CUSTOMER"],
  },
];

export function isPromise(obj) {
  return (
    !!obj &&
    (typeof obj === "object" || typeof obj === "function") &&
    typeof obj.then === "function"
  );
}

//休眠方法
export const sleep = async (second) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      resolve();
    }, second);
  });
};

//根据json中配置的id找到对应的组件
export const findComponentById = (sid, root) => {
  let result = null;
  const visit = (id, node) => {
    for (let sub of node.$children) {
      if (sub.schema?.id === id) {
        result = sub;
      }
      visit(id, sub);
    }
    return result;
  };
  visit(sid, root);
  return result;
};

// eslint-disable-next-line no-unused-vars
export function loading(opts = { delay: 0 }) {
  // eslint-disable-next-line no-unused-vars
  return function(target, propertyKey, descriptor) {
    let originFn = descriptor.value;
    descriptor.value = function(...p) {
      //console.log(`Calling ${propertyKey} with`, p);
      DelayLoadingService.getService().openLoading();
      try {
        let fnResult = originFn.apply(this, p);
        if (isPromise(fnResult)) {
          return fnResult
            .then((ret) => {
              if (opts.delay === 0) {
                DelayLoadingService.getService().closeLoadingImmediate();
              } else {
                DelayLoadingService.getService().closeLoading(opts.delay);
              }
              return ret;
            })
            .catch((err) => {
              DelayLoadingService.getService().closeLoadingImmediate();
              //此处抛给promise链而不是本函数自身外侧的catch方法,并且本函数外部的catch在关闭loading后也做了抛出,
              //所以异常未被吃掉,特别注意
              return Promise.reject(err); //或 throw err;
            });
        } else {
          DelayLoadingService.getService().closeLoadingImmediate();
          return fnResult;
        }
      } catch (err) {
        DelayLoadingService.getService().closeLoadingImmediate();
        throw err;
      }
    };
    return descriptor;
  };
}
