var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"alone",staticStyle:{"width":"150px","text-align":"right"}},[_c('label',{staticClass:"el-form-item__label",style:({ width: ((_vm.schema.labelWidth) + "px") }),attrs:{"for":"description"}},[_c('span',{staticStyle:{"color":"red","margin-right":"1px"}},[_vm._v("*")]),_c('span',[_vm._v(" 版本")])])]),_c('div',{staticStyle:{"flex":"1"}},_vm._l((_vm.value),function(version,index){return _c('div',{key:version.id},[_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{attrs:{"label-width":"0px","prop":_vm.schema.prop + '.' + index + '.date',"rules":{
              required: true,
              message: '发版时间不能为空',
              trigger: 'blur',
            }}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":_vm.elForm.disabled ? '' : '请选择发版时间'},model:{value:(version.date),callback:function ($$v) {_vm.$set(version, "date", $$v)},expression:"version.date"}})],1)],1),_c('el-col',{attrs:{"span":1}},[_vm._v(" ")]),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{attrs:{"label-width":"0px","prop":_vm.schema.prop + '.' + index + '.versionNumber',"rules":{
              required: true,
              message: '版本号不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{attrs:{"placeholder":_vm.elForm.disabled ? '' : '请输入版本号'},model:{value:(version.versionNumber),callback:function ($$v) {_vm.$set(version, "versionNumber", $$v)},expression:"version.versionNumber"}})],1)],1),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":2}},[(index === 0)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus","circle":"","size":"mini"},on:{"click":function () {
                _vm.schema.formData[_vm.schema.prop].push({
                  id: _vm.$options.filters._uuid(),
                  date: '',
                  versionNumber: '',
                  content: '',
                });
              }}}):_c('el-button',{attrs:{"type":"danger","icon":"el-icon-minus","circle":"","size":"mini"},on:{"click":function () {
                //删除逻辑
                var versionIndex = _vm.schema.formData[_vm.schema.prop].findIndex(
                  function (v) { return v.id === version.id; }
                );
                if (versionIndex >= 0) {
                  _vm.schema.formData[_vm.schema.prop].splice(versionIndex, 1);
                }
              }}})],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":21}},[_c('el-form-item',{attrs:{"label-width":"0px","prop":_vm.schema.prop + '.' + index + '.content',"rules":{
              required: true,
              message: '更新内容不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{attrs:{"placeholder":_vm.elForm.disabled ? '' : '请输入更新内容'},model:{value:(version.content),callback:function ($$v) {_vm.$set(version, "content", $$v)},expression:"version.content"}})],1)],1)],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }