<template>
  <div class="d-dialog">
    <el-dialog
      :modal="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :title="schema.title"
      :width="schema.width"
      :visible="schema.visible"
      @close="
        $emit(
          'update:schema',
          { ...schema, visible: false },
          'dialog:visible',
          path
        )
      "
    >
      <div>
        <fragment
          :key="`${schema.id}_${index}`"
          v-for="(item, index) in schema.items"
        >
          <component
            :is="item.xType"
            :schema="{
              ...item,
              ...$options.filters._frm_data(schema.formData),
            }"
            :path="`${path}.items[${index}]`"
            @update:schema="
              (sval, stype, spath) => {
                $emit('update:schema', sval, stype, spath);
              }
            "
          >
          </component>
        </fragment>
      </div>
      <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="schema.buttons[1].click($event)">
          确 定</el-button
        >
        <el-button
          @click="
            $emit(
              'update:schema',
              { ...schema, visible: false },
              'dialog:visible',
              path
            )
          "
          >取 消</el-button
        >
        
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DDialog",
  props: {
    schema: {
      type: Object,
      required: true,
      default: () => {
        return {
          visible: true,
        };
      },
    },
    path: {
      type: String,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
