<template>
  <div>
    <el-form-item
      :label="schema.label"
      :label-width="schema.labelWidth | nil('')"
      :rules="schema.rules | nil([])"
      :prop="schema.prop + '.selected'"
    >
      <div>
        {{ schema.allText }}
      </div>
      <div style="margin: 15px 0;"></div>
      <el-radio-group
        style="line-height:40px"
        :value="$options.filters.nil(value, {}).selected"
      >
        <el-radio
          v-for="opt in schema.opts"
          @click.native.prevent="onRadioClick(opt.value)"
          :label="opt.value"
          :key="opt.value"
          >{{ opt.label }}</el-radio
        >
      </el-radio-group>
    </el-form-item>

    <div style="margin: 15px 0;"></div>

    <el-form-item
      v-if="$options.filters.nil(value, {}).selected"
      label="选择有效期"
      :label-width="schema.labelWidth | nil('')"
      :prop="schema.prop + '.range'"
      :rules="[{ required: true, message: '请选择有效期', trigger: 'change' }]"
    >
      <q-date-range-picker
        :value="$options.filters.nil(value, {}).range"
        @input="
          (val) => {
            $emit('input', { ...value, range: val });
          }
        "
      />
    </el-form-item>
    <el-form-item     label="应用展示图片"  v-if="$options.filters.nil(value, {}).selected"
                      :prop="schema.prop + '.logo'"
                      >
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20" class="customer" style="position: relative">
          <q-image-upload  :schema="{
								xType: 'q-field',
								fType: 'q-image-upload',
						size: 50,
							}"   :value="$options.filters.nil(value, {}).logo"
                           @input="
          (val) => {
            $emit('input', { ...value, logo: val });
          }
        ">

          </q-image-upload>
        </el-col>
      </el-row>
    </el-form-item>

  </div>
</template>

<script>

  const v= {selected:1,range:[],imageUrl:''}
//const cityOptions = ["上海", "北京", "广州", "深圳"];
export default {
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  methods: {
    onRadioClick(val) {
      const current = (this.value ?? {}).selected;
      if (val === current) {
        //取消单选
        this.$emit("input", { ...this.value, selected: "" });
      } else {
        //选中单选
        this.$emit("input", { ...this.value, selected: val });
      }
    },
  },
  data() {
    return {};
  },
};
</script>
