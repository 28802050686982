const state = {
  basicPointLists: [], //基本点列表
  virtualPointLists: [], //虚拟点列表
  alertRuleLists: [], //报警点列表
  basicRelationDatas: [], //基本点关联点
  virtualRelationDatas: [], //虚拟点关联点
};

const mutations = {
  SET_BASICPOINT_LISTS: (state, data) => {
    state.basicPointLists = data;
  },
  SET_VIRTUALPOINT_LISTS: (state, data) => {
    state.virtualPointLists = data;
  },
  SET_ALERTRULE_LISTS: (state, data) => {
    state.alertRuleLists = data;
  },
  SET_BASIC_RELATION_DATAS: (state, data) => {
    state.basicRelationDatas = data;
  },
  SET_VIRTUAL_RELATION_DATAS: (state, data) => {
    state.virtualRelationDatas = data;
  },

};

const actions = {
  setBasicPointLists({ commit }, data) {
    commit("SET_BASICPOINT_LISTS", data);
  },
  setVirtualPointLists({ commit }, data) {
    commit("SET_VIRTUALPOINT_LISTS", data);
  },
  setAlertRuleListsLists({ commit }, data) {
    commit("SET_ALERTRULE_LISTS", data);
  },
  setBasicRelationDatas({ commit }, data) {
    commit("SET_BASIC_RELATION_DATAS", data);
  },
  setVirtualRelationDatas({ commit }, data) {
    commit("SET_VIRTUAL_RELATION_DATAS", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
