<template>
  <el-menu :default-active="schema.defaultActive">
    <menu-bar-item
      :key="item.id"
      :schema="item"
      v-for="item in schema.items"
      :roles="roles"
    >
    </menu-bar-item>
  </el-menu>
</template>

<script>
import MenuBarItem from "@/components/MenuBarItem.vue";

export default {
  name: "MenuBar",
  components: {
    MenuBarItem,
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  created(){
  }
};
</script>

<style lang="scss">
.iconfont{
  
}


</style>
