<template>
  <fragment>
    <q-version-list
      v-if="schema.fType === 'q-version-list'"
      :schema="{ ...schema, id: null }"
      v-model="schema.formData[schema.prop]"
    >
    </q-version-list>
    <q-radioboxes
      v-else-if="schema.fType === 'q-radioboxes'"
      :schema="{ ...schema, id: null }"
      v-model="schema.formData[schema.prop]"
    ></q-radioboxes>
    <q-address
      v-else-if="schema.fType === 'q-address'"
      :schema="{ ...schema, id: null }"
      v-model="schema.formData[schema.prop]"
    ></q-address>
    <q-subscription-list
      v-else-if="schema.fType === 'q-subscription-list'"
      :schema="{ ...schema, id: null }"
      v-model="schema.formData[schema.prop]"
    ></q-subscription-list>
    <el-form-item
      v-else
      ref="formItem"
      v-show="schema.fType !== 'q-hidden' && schema.hidden !== true"
      :label="schema.label"
      :label-width="schema.labelWidth | nil('')"
      :prop="schema.prop"
      :rules="schema.rules | nil([])"
    >
      <q-select
        v-if="schema.fType === 'q-select'"
        :schema="{ ...schema, id: null }"
        v-model="schema.formData[schema.prop]"
      ></q-select>
      <q-checkboxes
        v-else-if="schema.fType === 'q-checkboxes'"
        :schema="{ ...schema, id: null }"
        v-model="schema.formData[schema.prop]"
      ></q-checkboxes>
      <q-checkbox-group
        v-else-if="schema.fType === 'q-checkbox-group'"
        :schema="{ ...schema, id: null }"
        v-model="schema.formData[schema.prop]"
      >
      </q-checkbox-group>

      <q-image-upload
        v-else-if="schema.fType === 'q-image-upload'"
        :schema="{ ...schema, id: null }"
        v-model="schema.formData[schema.prop]"
      >
      </q-image-upload>
      <q-location-cascader
        v-else-if="schema.fType === 'q-location-cascader'"
        :schema="{ ...schema, id: null }"
        v-model="schema.formData[schema.prop]"
      ></q-location-cascader>
      <q-industry-cascader
        v-else-if="schema.fType === 'q-industry-cascader'"
        :schema="{ ...schema, id: null }"
        v-model="schema.formData[schema.prop]"
      >
      </q-industry-cascader>
      <q-input-number
        v-else-if="schema.fType === 'q-input-number'"
        :schema="{ ...schema, id: null }"
        v-model="schema.formData[schema.prop]"
      ></q-input-number>
      <el-input
        v-else
        :style="schema.style | nil({})"
        :maxlength="schema.max"
        :type="schema.rawType | nil('text')"
        v-model="schema.formData[schema.prop]"
        :placeholder="elForm.disabled ? '' : schema.placeholder"
      ></el-input>
    </el-form-item>
  </fragment>
</template>
<script>
export default {
  props: {
    schema: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    // formData: {
    //   type: Object,
    //   required: true,
    //   default: () => {},
    // },
  },
  inject: ["elForm"],
  methods: {
    clearValidate() {
      this.$refs["formItem"].clearValidate();
    },
    setData(cb) {
      cb(this.schema.formData[this.schema.prop]);
    },
  },
};
</script>
