<template>
  <el-col
    :span="schema.span | nil(24)"
    :offset="schema.offset | nil(0)"
    :push="schema.push | nil(0)"
    :pull="schema.pull | nil(0)"
    :xs="schema.xs | nil({})"
    :sm="schema.sm | nil({})"
    :md="schema.md | nil({})"
    :lg="schema.lg | nil({})"
    :xl="schema.xl | nil({})"
    :tag="schema.tag | nil('div')"
  >
    <fragment
      :key="`${schema.id}_${index}`"
      v-for="(item, index) in schema.items"
    >
      <component
        :is="item.xType"
        :schema="{ ...item, ...$options.filters._frm_data(schema.formData) }"
        :path="`${path}.items[${index}]`"
        @update:schema="
          (sval, stype, spath) => {
            $emit('update:schema', sval, stype, spath);
          }
        "
      >
      </component>
    </fragment>
  </el-col>
</template>
<script>
export default {
  name: "QCol",
  props: {
    schema: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
};
</script>
