<template>
  <div style="display:flex;">
    <div class="alone" style="width:150px;text-align: right;">
      <label
        for="description"
        class="el-form-item__label"
        :style="{ width: `${schema.labelWidth}px` }"
      >
        <span style="color:red;margin-right:1px">*</span>
        <span> 版本</span></label
      >
    </div>
    <div style="flex:1">
      <div :key="version.id" v-for="(version, index) in value">
        <el-row>
          <el-col :span="10">
            <el-form-item
              label-width="0px"
              :prop="schema.prop + '.' + index + '.date'"
              :rules="{
                required: true,
                message: '发版时间不能为空',
                trigger: 'blur',
              }"
            >
              <el-date-picker
                v-model="version.date"
                style="width:100%"
                type="date"
                :placeholder="elForm.disabled ? '' : '请选择发版时间'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="10">
            <el-form-item
              label-width="0px"
              :prop="schema.prop + '.' + index + '.versionNumber'"
              :rules="{
                required: true,
                message: '版本号不能为空',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="version.versionNumber"
                :placeholder="elForm.disabled ? '' : '请输入版本号'"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" style="text-align:center">
            <el-button
              v-if="index === 0"
              type="primary"
              icon="el-icon-plus"
              circle
              size="mini"
              @click="
                () => {
                  schema.formData[schema.prop].push({
                    id: $options.filters._uuid(),
                    date: '',
                    versionNumber: '',
                    content: '',
                  });
                }
              "
            ></el-button>
            <el-button
              v-else
              type="danger"
              icon="el-icon-minus"
              circle
              size="mini"
              @click="
                () => {
                  //删除逻辑
                  const versionIndex = schema.formData[schema.prop].findIndex(
                    (v) => v.id === version.id
                  );
                  if (versionIndex >= 0) {
                    schema.formData[schema.prop].splice(versionIndex, 1);
                  }
                }
              "
            ></el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item
              label-width="0px"
              :prop="schema.prop + '.' + index + '.content'"
              :rules="{
                required: true,
                message: '更新内容不能为空',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="version.content"
                :placeholder="elForm.disabled ? '' : '请输入更新内容'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QVersionList",
  inject: ["elForm"],
  props: {
    value: {
      //required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
};
</script>
<style scoped></style>
