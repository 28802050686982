<template>
	<fragment>
		<el-upload :accept="accept" class="avatar-uploader" ref="upload_img" :action="ossUploadUrl" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :on-error="handleError">
			<img v-if="value" :src="value" class="avatar" />
			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
      <div v-if="value" slot="tip" class="delete" @click="handleRemove"><i class="el-icon-delete"></i></div>
		</el-upload>
	</fragment>
</template>

<script>
import { ossUploadUrl } from '@/api/ossApi';
import BizCode from '@/utils/BizCode';
import { Notification } from 'element-ui';

export default {
	name: 'QImageUpload',
	props: {
		value: {
			//required: true,
		},
		schema: {
			type: Object,
			required: true,
		},
		// path: {
		//   type: String,
		//   required: true,
		// },
	},
	data() {
		return {
			size: 2,
			legal: ['jpg', 'jpeg', 'gif', 'bmp', 'png'],
			accept: '.jpg,.jpeg,.gif,.bmp,.png',
			ossUploadUrl,
			imageUrl: '',
		};
	},
	methods: {
		// eslint-disable-next-line no-unused-vars
		handleAvatarSuccess(res, file) {
			// console.log(file, "file");
			if (res.code === BizCode.OK) {
				this.$emit('input', res.data.url);
			} else {
				//这里走的不是request
				//所以需要手动提示错误
				Notification({
					title: '系统错误',
					message: '图片上传错误,请联系管理员',
					type: 'error',
				});
			}
			// console.log(res, "res");
			// this.imageUrl = URL.createObjectURL(file.raw);
			// console.log(this.imageUrl, " this.imageUrl");
		},
		// 删除
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.$emit('input', '');
		},
		// eslint-disable-next-line no-unused-vars
		handleError(err, file, fileList) {
			Notification({
				title: '系统错误',
				message: '图片上传错误,请联系管理员',
				type: 'error',
			});
		},
		beforeAvatarUpload(file) {
			//文件扩展名
			const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
			//是否合法扩展名
			const isLegal = this.legal.includes(ext);

			//是否超过最大限制
			const isTooLarge = file.size / 1024 / 1024 > this.schema.size;

			//是否扩展名不合法
			if (!isLegal) {
				this.$message.error(`支持${this.legal.map((l) => '*.' + l).join(';')};格式`);
				return false;
			}

			//是否超大
			if (isTooLarge) {
				this.$message.error(`上传图片大小不能超过 ${this.schema.size ? this.schema.size : this.size}MB!`);
				return false;
			}

			return true;
		},
	},
};
</script>
<style>
.avatar-uploader{
  width: 100px;
  height: 100px;

}
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
  
}
.avatar-uploader:hover .delete{
  display: inline-block;
}
.avatar-uploader .delete{
  display: none;
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, .5);
  left: 1px;
  top: 1px;
  line-height: 100px;
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  z-index: 999;
  border-radius: 5px;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
}
.avatar {
	width: 100px;
	height: 100px;
	display: block;
}
</style>
