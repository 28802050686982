import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

import corpUser from "./modules/corpUser";
import tempImport from "./modules/tempImport"; //模板导入信息

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    corpUser,
    tempImport
  },
  getters,
});

export default store;
