const getters = {
  self: (state) => state.corpUser.self,
  roles: (state) => state.corpUser.roles,
  basicPointLists:state=>state.tempImport.basicPointLists,
  virtualPointLists:state=>state.tempImport.virtualPointLists,
  alertRuleLists:state=>state.tempImport.alertRuleLists,
  basicRelationDatas:state=>state.tempImport.basicRelationDatas,
  virtualRelationDatas:state=>state.tempImport.virtualRelationDatas,
};
export default getters;
