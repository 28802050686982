<template>
  <el-table
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
    :data="data"
    :style="{ width: $options.filters.nil(schema.width, '100%') }"
  >
    <el-table-column
      :key="`${schema.id}_${index}`"
      :prop="column.prop"
      :label="column.label"
      :width="column.width"
      v-for="(column, index) in schema.columns"
    >
      <template slot-scope="scope">
        <fragment>
          <!-- <div v-if="column.tpl">
            {{column.tpl(scope.row)}}
        </div> -->
          <q-container
            v-if="column.tpl"
            :schema="{
              xType: 'q-container',
              items: column.tpl(scope.row),
            }"
            :path="`columns_${index}`"
          >
          </q-container>
          <!-- <div v-else-if="column.actions">
            <span
              :key="`${schema.id}_${index}_${aindex}`"
              v-for="(action, aindex) in column.actions"
            >
              <el-button
                @click="action.click ? action.click($event, scope.row) : void 0"
                >{{ action.text }}</el-button
              >
            </span>
          </div> -->
          <div v-else-if="column.render">
              {{ column.render(scope.row) }}
          </div>
          <div v-else>
            {{ scope.row | _path(column.prop) | nil }}
          </div>
        </fragment>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped lang="scss"></style>
