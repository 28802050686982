<template>
  <fragment>
    <el-select
      clearable
      :loading="loading"
      :placeholder="elForm.disabled?'':schema.placeholder"
      :value="value"
      @input="onInput"
      @change="onSelectChange"
    >
      <el-option
        v-for="opt in options"
        :key="opt.value"
        :label="opt.label"
        :value="opt.value"
      >
      </el-option>
    </el-select>
  </fragment>
</template>

<script>
export default {
  name: "QSelect",
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  async mounted() {
    //观察,当remoteOnce发生变化时,再来一次
    if (this.schema.remoteOnce) {
      this.loading = true;
      const result = await this.schema.remoteOnce();
      this.loading = false;
      this.options = [...result];
    }
  },
  inject:['elForm'],
  data() {
    return {
      loading: false,
      options: this.schema.opts ?? [],
    };
  },
  methods: {
    onInput(val) {
      this.$emit("input", val);
      //console.log(val,'val');
    },
    onSelectChange(val) {
      if (this.schema.onSelectChange) {
        this.schema.onSelectChange(val);
      }
    },
  },

  watch: {
    value: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        if (val !== oldVal) {
          if (this.schema.onValueChange) {
            this.schema.onValueChange(val);
          }
        }
      },
    },
    "schema.opts": {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.options = [...val];
        }
      },
    },
  },
  // methods: {
  //   // eslint-disable-next-line no-unused-vars
  //   remoteMethod(query) {
  //     //console.log(query);
  //     this.loading = true;
  //     window.setTimeout(() => {
  //       this.options = [];
  //       this.options.push({
  //         label: "1",
  //         value: "1",
  //       });
  //       this.options.push({
  //         label: "2",
  //         value: "2",
  //       });
  //       this.loading = false;
  //     }, 3000);
  //   },
  // },
};
</script>
<style scoped></style>
