// eslint-disable-next-line no-unused-vars
// import AuthClient from "@/utils/AuthClient";
// import applicationApi from "@/api/application";
// import BizCode from "@/utils/BizCode";
import store from "./store";
import { routes } from "./router";
import Enumerable from "linq";
// eslint-disable-next-line no-unused-vars
import getPageTitle from "@/utils/get-page-title";
// import { MenuJson } from "@/utils/index";

// eslint-disable-next-line no-unused-vars
const addRoutes = (router, menuRoles) => {
  for (let route of routes) {
    if (
      menuRoles.includes(route?.meta?.ROLE) ||
      route?.meta?.ROLE === "ALL:ALLOW"
    ) {
      router.addRoute(route);
    }
    if (route.children && route.children.length > 0) {
      for (const it of route.children) {
        if (
          menuRoles.includes(it?.meta?.ROLE) ||
          it?.meta?.ROLE === "ALL:ALLOW"
        ) {
          router.addRoute(it);
        }
      }
    }

  }
};

//获取左侧的菜单角色
const getMenuRoles = () => {
  const resources = store.getters.self.role.resources.map(
    (r) => r.resource.split(":")[0]
  );
  const menuRoles = Enumerable.from(resources)
    .distinct()
    .toArray();
    console.log(menuRoles,11111);
  return menuRoles;
};

//返回首页需要页面地址
// const getHomeRedirect = (serverRole) => {
//   //平展
//   const flat = [];
//   //递归查找需要激活的menuItem的id属性
//   const visit = (subs) => {
//     for (let sub of subs) {
//       flat.push(sub);
//       if ((sub.items?.length ?? 0) > 0) {
//         visit(sub.items);
//       }
//     }
//   };
//   visit(MenuJson);

//   //目标
//   const target = flat
//     .filter((m) => m.link !== undefined && m.link !== null && m.link !== "")
//     .find((m) => m.ROLE.some((r) => serverRole.includes(r)));
//   //默认跳转链接
//   return target?.link ?? undefined;
// };

//增加router的功能
export const enhanceRouter = (router) => {
  // eslint-disable-next-line no-unused-vars
  router.beforeEach(async (to, from, next) => {

    document.title = getPageTitle(to.meta.title, to.meta.isEntry);

    if (!store.getters.self.done) {
      try {
        //loading
        await store.dispatch("corpUser/self");
        //左侧菜单权限
        const menuRoles = getMenuRoles();

        //提交roles
        store.commit("corpUser/SET_ROLES", [...menuRoles]);
        //动态路由设置
        addRoutes(router, menuRoles);
        console.log(menuRoles);
        
        //setMenu()
        //首页 跳转roles
        if (to.path === "/") {
          // const redirectPath = getHomeRedirect(menuRoles) ?? "/role";
          const redirectPath = "/show";
          next({ ...to, path: redirectPath, replace: true });
        } else {
          next({ ...to, replace: true });
        }
      } catch (err) {
        console.log(err, "err");
        //获取失败或者权限有问题
        //应该定位到无权限页面
        next();
      }
    } else {
      //每次正常跳转
      next();
    }
  });
};
