import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
import Layout from "../views/Layout.vue";

Vue.use(VueRouter);

export const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   redirect: "/role",
  //   exact: true,    
  // },
  {
    path: '/',
    name: 'List',
    component: Layout,
    meta: {
      ROLE: "ALL:ALLOW",
    },
    children: [
      {
        path: "role",
        name: "Role",
        meta: {
          ROLE: "ROLE",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QRole.vue"),
      },
      {
        path: "staff",
        name: "Staff",
        meta: {
          ROLE: "CORP_USER",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QStaff.vue"),
      },
    
      // 内部用户管理 新增内部用户
      {
        path: "editStaff",
        name: "EditStaff",
        meta: {
          ROLE: "CORP_USER",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QEditStaff.vue"),
      },
      {
        path: "application",
        name: "Application",
        meta: {
          ROLE: "APPLICATION",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QApplication.vue"),
      },
      {
        path: "editApplication",
        name: "EditApplication",
        meta: {
          ROLE: "APPLICATION",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QEditApplication.vue"),
      },
      {
        path: "version",
        name: "Version",
        meta: {
          ROLE: "VERSION",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QVersion.vue"),
      },
      {
        path: "editVersion",
        name: "EditVersion",
        meta: {
          ROLE: "VERSION",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/QEditVersion.vue"),
      },
      {
        path: "customer",
        name: "Customer",
        meta: {
          ROLE: "CUSTOMER",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QCustomer.vue"),
      },

      //权限限制
      {
        path: "authority",
        name: "Authority",
        meta: {
          ROLE: "ROLE",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QAuthority.vue"),
      },
      {
        path: "viewCustomer",
        name: "ViewCustomer",
        meta: {
          ROLE: "CUSTOMER",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QViewCustomer.vue"),
      },
      {
        path: "resource",
        name: "Resource",
        meta: {
          ROLE: "APPLICATION",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QResource.vue"),
      },
      // 应用管理  模板管理列表
      {
        path: "templateManage",
        name: "TemplateManage",
        meta: {
          ROLE: "APPLICATION",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/TemplateList.vue"),
      },
      // 应用管理  模板管理编辑
      {
        path: "templateEdit",
        name: "TemplateEdit",
        meta: {
          ROLE: "APPLICATION",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/TemplateEdit.vue"),
      },
      // 应用管理  数据配置
      {
        path: "templateDataConfig",
        name: "TemplateDataConfig",
        meta: {
          ROLE: "APPLICATION",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/TemplateDataConfig.vue"),
      },

      {
        path: "editCustomer",
        name: "EditCustomer",
        meta: {
          ROLE: "CUSTOMER",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QEditCustomer.vue"),
      },
      {
        path: "changePwd",
        name: "ChangePwd",
        meta: {
          ROLE: "ALL:ALLOW",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ChangePwd.vue"),
      },
    ]
  },
  {
    path: "/show",
    name: "Show",
    meta: {
      ROLE: "ALL:ALLOW",
    },
    component: () => import("../views/ShowCenter.vue"),
  },
 
];

const router = new VueRouter({
  mode: "history",
  routes: [],
});

export default router;
