<template>
	<div class="q-button-panel" :style="{paddingLeft: schema.paddingLeft}">
		<component
			:key="`${schema.id}_${index}`"
			v-for="(item, index) in schema.items"
			:is="item.xType"
			:schema="{ ...item, ...$options.filters._frm_data(schema.formData) }"
			:path="`${path}.items[${index}]`"
			@update:schema="
				(sval, stype, spath) => {
					$emit('update:schema', sval, stype, spath);
				}
			"
		>
		</component>
	</div>
</template>

<script>
export default {
	name: 'QButtonPanel',
	props: {
		schema: {
			type: Object,
			required: true,
		},
		path: {
			type: String,
			required: true,
		},
	},
};
</script>
<style scoped></style>
