<template>
  <fragment>
    <el-button
      v-auth="$options.filters.nil(schema.auth, 'ALL:SHOW')"
      v-show="$options.filters.nil(schema.visible, true)"
      :type="schema.type | nil('')"
      @click="
        (event) => {
          schema.click(event);
        }
      "
      >{{ schema.text }}</el-button
    >
  </fragment>
</template>
<script>
export default {
  name: "QButton",
  props: {
    schema: {
      type: Object,
      required: true,
    },
  },
};
</script>
