import corpUserApi from "@/api/corpUser";
import BizCode from "@/utils/BizCode";
const state = { self: { done: false } ,roles:[]};

const mutations = {
  SET_SELF(state, self) {
    state.self = self;
  },
  SET_ROLES(state, roles) {
    state.roles = [...roles];
  },
};

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async self({ commit }) {
    const result = await corpUserApi.self();
    console.log(result,'------------result------------');
    if (result.code === BizCode.OK) {
      //commit
      commit("SET_SELF", { ...result.data, done: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
