//验证客户端
class AuthClient {
  checking = false;
  cycleSpan = 3000;

  //单例singleton
  static _instance;
  static getInstance() {
    if (AuthClient._instance === null || AuthClient._instance === undefined) {
      AuthClient._instance = new AuthClient();
    }
    return AuthClient._instance;
  }

  //登出
  logout() {
    window.location.href = "/server/logout";
    // const result = await fetch("/server/back_channel_logout");
    // console.log(result, "result");
  }

  async sleep(second) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        resolve();
      }, second);
    });
  }
  startChecking(cycle = null) {
    this.checking = true;
    if (cycle) {
      this.cycleSpan = cycle;
    }
    this.checkState();
  }
  stopChecking() {
    this.checking = false;
  }
  async checkState() {
    while (this.checking) {
      const result = await this.requestForCheck();
      if (result === 403) {
        //重定向
        //window.location.href="https://www.baidu.com"
        const path = window.location.pathname + window.location.search;
        console.log(path, "path");
        window.location.href = "/server/landing?to=" + path;
        //重定向后直接返回
        break;
      }
      await this.sleep(this.cycleSpan);
    }
  }
  async requestForCheck() {
    try {
      const result = await fetch("/server/probe");
      if (result.status === 403) {
        console.log(window.location.hash);
        console.log(result.status, "result");
        return 403;
      }
      //其他正常token并未失效
    } catch (err) {
      console.log(err);
    }
    return 200;
  }
}
export default AuthClient;
