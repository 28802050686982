<template>
	<el-container>
		<el-header class="header-bar" height="64px">
			<span class="logo" @click="handleShow()">
				<img src="@/assets/images/allx.svg" />
			</span>
			<span class="logo_title" @click="handleShow()"> 奥立信工业互联网平台客户管理中心 </span>
			<span class="space"></span>
			<span>
				<q-dropdown-nav />
			</span>
		</el-header>
		<el-container>
			<el-aside width="240px">
				<menu-bar :schema="schema.menuBar" :roles="roles" />
			</el-aside>
			<el-main> <router-view /> </el-main>
		</el-container>
	</el-container>
</template>

<script>
import MenuBar from '@/components/MenuBar.vue';
import QDropdownNav from '@/views/QDropdownNav';
import { MenuJson } from '@/utils/index';
import { mapState } from 'vuex';

export default {
	name: 'Layout',

	components: {
		MenuBar,
		QDropdownNav,
	},
	data() {
		return {
			schema: {
				menuBar: {
					xType: 'menuBar',
					defaultActive: undefined,
					items: [...MenuJson],
				},
			},
		};
	},
	methods: {
		logout() {
			window.location.href = '/server/logout';
		},
		handleShow() {
			this.$router.push({
				name: 'Show',
			});
		},
	},
	computed: mapState({
		roles: (state) => state.corpUser.roles,
	}),
	watch: {
		//观察其要领是defaultActive需要随着当前路由的变化而变化
		'$route.path': {
			async handler(p) {
				let activeKey = null;
				//递归查找需要激活的menuItem的id属性
				const visit = (subs) => {
					for (let sub of subs) {
						if (sub.link === p) {
							activeKey = sub.active;
						}
						if ((sub.items?.length ?? 0) > 0) {
							visit(sub.items);
						}
					}
				};
				visit(this.schema.menuBar.items);
				//所有数据
				this.schema.menuBar.defaultActive = activeKey;
			},
			immediate: true, //立即执行一次需要
		},
	},
};
</script>

<style scoped lang="scss">
.el-container {
	/*设置内部填充为0，几个布局元素之间没有间距*/
	padding: 0px;
	/*外部间距也是如此设置*/
	margin: 0px;
	/*统一设置高度为100%*/
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: #0f141c; //rgba(15, 24, 38, 1)
}

.header-bar {
	display: flex;
	align-content: flex-start;
	align-items: center;
}

.title-2 {
	color: rgba(193, 198, 204, 1);
	font-size: 20px;
}

.title-3 {
	color: rgba(193, 198, 204, 1);
	font-size: 14px;
}

.space {
	flex: auto;
}

.logo_title {
	font-size: 18px;
	cursor: pointer;
}
</style>
