//import defaultSettings from '@/settings'

export default function getPageTitle (pageTitle, isEntry) {
  if (isEntry) {
    return `${pageTitle}`
  } else if (pageTitle) {
    return `客户管理中心`
  } else {
    return `客户管理中心`
  }
}


