<template>
  <el-cascader
    clearable
    :placeholder="elForm.disabled?'':schema.placeholder"
    :value="value"
    @input="(val) => $emit('input', val)"
    :options="options"
  ></el-cascader>
</template>

<script>
import Industry from "@/assets/json/Industry.json";

export default {
  name: "QLocationCascader",
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  inject:['elForm'],
  data() {
    return {
      options: this.getTreeData(this.formatLocation(Industry.districts)),
    };
  },
  methods: {
    getTreeData(data) {
      data.forEach((r) => {
        if (r.children.length < 1) {
          r.children = undefined;
        } else {
          this.getTreeData(r.children);
        }
      });
      return data;
    },

    formatLocation(locationArr) {
      const arr = [];
      let obj = {};
      locationArr.forEach((item) => {
        const tmp = { ...item, children: [] };
        if (tmp.districts) {
          tmp.children = this.formatLocation(tmp.districts);
          obj = {
            value: tmp.adcode ? tmp.adcode : tmp.id,
            label: tmp.name,
            children: tmp.children,
          };
        }
        arr.push(obj);
      });
      return arr;
    },
  },
};
</script>
