<template>
  <div style="display:flex;" class="el-form-item">
    <div :style="{ width: `${elForm.labelWidth}` }">
      <label
        :for="schema.prop"
        class="el-form-item__label"
        :style="{ width: `${elForm.labelWidth}` }"
      >
        <span style="color:red;margin-right:1px">*</span>
        <span> {{ schema.label }}</span></label
      >
    </div>
    <div style="flex:1">
      <div
        :key="subscription.id"
        v-for="subscription in value"
        style="display:flex;"
      >
        <div>
          <el-input :value="subscription.name" />
        </div>
        <div style="display:flex;margin-left:15px">
          <label class="el-form-item__label">
            账号有效期
          </label>
          <q-date-range-picker
            :value="[subscription.startDate, subscription.endDate]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QSubscriptionList",
  inject: ["elForm"],
  props: {
    value: {
      //required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  mounted() {
    //console.log(this,'elForm')
  },
};
</script>
<style scoped></style>
