<template>
  <el-form
    ref="q-form"
    :inline="schema.inline | nil(false)"
    :model="formData"
    :label-width="schema.labelWidth | nil('')"
    :disabled="schema.disabled | nil(false)"
  >
    <!--动态删除元素时有比较严重的bug不得不改-->
    <span :key="`${schema.id}_${index}`" v-for="(item, index) in schema.items">
      <component
        :is="item.xType"
        :schema="{ ...item, formData }"
        :path="`${path}.items[${index}]`"
        @update:schema="
          (sval, stype, spath) => {
            $emit('update:schema', sval, stype, spath);
          }
        "
      >
      </component>
    </span>
  </el-form>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: () => null,
    },
    path: {
      type: String,
      required: true,
    },
  },

  data() {
    //初始化设置默认值
    //嵌套表单在这里可以做支持
    //data的问题怎么处理|浮上去还是靠自身也要在这里做处理
    //目前来讲是靠自身来进行处理
    //绑定表达式也要靠这里来进行处理
    //目前这些复杂功能先不做支持,先以业务为主,重点是验证此dsl是否有效
    //若有效则优先进行可视化,然后在对原始dsl模型做新的改进
    let defaultData = {};
    const visit = (node) => {
      if (node.xType === "q-field") {
        if (
          node.fType === "q-checkbox-group" ||
          node.fType === "q-version-list" ||
          node.fType === "q-checkboxes"
        ) {
          defaultData[node.prop] = [];
        } else {
          defaultData[node.prop] = null;
        }
      }
      if (node.items && node.items.length > 0) {
        for (let item of node.items) {
          visit(item);
        }
      }
    };
    visit(this.schema);
    //ret
    return {
      formData: this.data ?? defaultData,
    };
  },

  methods: {
    validate(cb = () => {}) {
      return this.$refs["q-form"].validate((valid) => {
        cb(valid);
      });
    },
    clearValidate() {
      return this.$refs["q-form"].clearValidate();
    },
    getData() {
      return this.formData;
    },
    setData(data) {
      if (typeof data === "function") {
        data(this.formData);
      } else {
        this.formData = data;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
