<template>
  <div>
    <el-checkbox
      :indeterminate="isIndeterminate"
      :value="checkAll"
      @change="handleCheckAllChange"
      >{{ schema.allText }}</el-checkbox
    >
    <div style="margin: 15px 0;"></div>
    <el-checkbox-group
      :value="value"
      @input="
        (val) => {
          $emit('input', val);
        }
      "
    >
      <el-checkbox
        v-for="opt in schema.opts"
        :label="opt.value"
        :key="opt.value"
        >{{ opt.label }}</el-checkbox
      >
    </el-checkbox-group>
  </div>
</template>

<script>
//const cityOptions = ["上海", "北京", "广州", "深圳"];
export default {
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {};
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleCheckAllChange(val) {
      if (this.checkAll) {
        //清空
        this.value.splice(0, this.value.length);
      } else {
        //全选
        this.value.splice(0, this.value.length);
        for (let opt of this.schema.opts) {
          this.value.push(opt.value);
        }
      }
      //this.checkedCities = val ? cityOptions : [];
      //this.isIndeterminate = false;
    },
  },
  computed: {
    isIndeterminate() {
      return (
        this.value?.length > 0 && this.value?.length < this.schema.opts?.length
      );
    },
    checkAll() {
      return this.value?.length === this.schema.opts?.length ? true : false;
    },
  },
};
</script>
