<template>
  <div class="q-data-grid">
    <q-table :loading="loading" :schema="schema.table" :data="tableData">
    </q-table>
    <el-pagination
      v-if="schema.pagingEnable"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import BizCode from "@/utils/BizCode";
//import qs  from 'qs';
import QTable from "@/components/QTable.vue";
import { isPromise } from "@/utils/index";

export default {
  name: "QDataGrid",
  components: { QTable },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },

  async mounted() {
    //挂载事件
    //this.syncServer();
  },
  data() {
    return {
      tableData: this.data,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      loading: false,
      queryParams: {},
      orderParams: {},
    };
  },
  methods: {
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
      this.pageSize = val;
      //this.syncServer();
      this.changeRoute();
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`);
      this.currentPage = val;
      //this.syncServer();
      this.changeRoute();
    },
    //设置查询参数
    setQueryParams(query) {
      this.queryParams = { ...query };
      this.changeRoute();
    },
    async syncServer() {
      if (this.schema.pagingEnable) {
        const {
          getPageList = () => {},
          // page = 1,
          // size = 5,
          // queryParams = {},
          // orderParams = {},
        } = this.schema;

        let result = getPageList({
          page: this.currentPage,
          size: this.pageSize,
          ...this.queryParams,
          ...this.orderParams,
        });

        if (isPromise(result)) {
          //this.loading = true;
          result = await result;
          //this.loading = false;
        }

        //assgin Data to tableData
        if (result?.code === BizCode.OK) {
          const { data } = result;
          this.total = data.total;
          this.tableData = data.records;
        }
      } else {
        const {
          getList = () => {},
          // page = 1,
          // size = 5,
          // queryParams = {},
          // orderParams = {},
        } = this.schema;

        let result = getList();

        if (isPromise(result)) {
          //this.loading = true;
          result = await result;
          //this.loading = false;
        }

        console.log(result, "result............");
        //assgin Data to tableData
        if (result?.code === BizCode.OK) {
          const { data } = result;
          //this.total = data.total;
          this.tableData = data;
        }
      }
    },

    //改变路由change route
    changeRoute() {
      console.log("change");
      // eslint-disable-next-line no-unused-vars
      const { page, size, queryParams, t, ...other } = this.$route.query ?? {}; //其他不相干参数也不能丢需要保持住
      this.$router.push({
        path: this.$route.path,
        query: {
          page: this.currentPage,
          size: this.pageSize,
          queryParams: window.encodeURI(JSON.stringify(this.queryParams ?? {})),
          //orderParams: this.orderParams,
          t: new Date().getTime(),
          ...other,
        },
      });
    },
  },
  watch: {
    "$route.query": {
      handler(p) {
        // this.searchForm.page = parseInt(p.page || 1);
        // this.searchForm.key = p.key || '';
        // this.searchForm.classification = p.classification || '';
        // this.searchForm.status = p.status || '';
        // this.doSearchForm = { ...this.searchForm };
        this.currentPage = parseInt(p.page ?? 1);
        this.pageSize = parseInt(p.size ?? 10);

        if (p?.queryParams !== null && p?.queryParams !== undefined) {
          this.queryParams = JSON.parse(window.decodeURI(p.queryParams));
        } else {
          this.queryParams = {};
        }

        //this.setOrderParams(p.orderParams ?? {});
        //this.fetchData();
        //否则手动控制
        this.syncServer();
        // if (this.schema?.autoLoad ?? true) {
        //   this.syncServer(); //获取数据
        // }
      },
      immediate: true, //立即执行一次需要
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
