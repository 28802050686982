var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-dialog"},[_c('el-dialog',{attrs:{"modal":true,"close-on-click-modal":false,"destroy-on-close":true,"title":_vm.schema.title,"width":_vm.schema.width,"visible":_vm.schema.visible},on:{"close":function($event){return _vm.$emit(
        'update:schema',
        Object.assign({}, _vm.schema, {visible: false}),
        'dialog:visible',
        _vm.path
      )}}},[_c('div',_vm._l((_vm.schema.items),function(item,index){return _c('fragment',{key:((_vm.schema.id) + "_" + index)},[_c(item.xType,{tag:"component",attrs:{"schema":Object.assign({}, item,
            _vm.$options.filters._frm_data(_vm.schema.formData)),"path":(_vm.path + ".items[" + index + "]")},on:{"update:schema":function (sval, stype, spath) {
              _vm.$emit('update:schema', sval, stype, spath);
            }}})],1)}),1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.schema.buttons[1].click($event)}}},[_vm._v(" 确 定")]),_c('el-button',{on:{"click":function($event){return _vm.$emit(
            'update:schema',
            Object.assign({}, _vm.schema, {visible: false}),
            'dialog:visible',
            _vm.path
          )}}},[_vm._v("取 消")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }