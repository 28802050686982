<template>
  <div class="block">
    <el-date-picker
      :value="value"
      @input="
        (val) => {
          $emit('input', val);
        }
      "
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      :start-placeholder="elForm.disabled ? '' : '开始日期'"
      :end-placeholder="elForm.disabled ? '' : '结束日期'"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  inject: ["elForm"],
  props: {
    value: {
      required: true,
    },
    // schema: {
    //   type: Object,
    //   required: true,
    // },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 29);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 89);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      //value2: ''
    };
  },
};
</script>
