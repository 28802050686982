<template>
  <div class="q-page">
    <div
      class="page_title"
      v-if="schema.title !== undefined && schema.title !== null"
    >
      {{ schema.title }}
    </div>
    <div class="q-page-content">
      <fragment
        :key="`${schema.id}_${index}`"
        v-for="(item, index) in schema.items"
      >
        <component
          :is="item.xType"
          :schema="{ ...item, ...$options.filters._frm_data(schema.formData) }"
          :path="`${path}.items[${index}]`"
          @update:schema="
            (sval, stype, spath) => {
              $emit('update:schema', sval, stype, spath);
            }
          "
        >
        </component>
      </fragment>
    </div>
  </div>
</template>

<script>
export default {
  name: "QPage",
  props: {
    schema: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
</style>
