import axios from "axios";
import { Notification } from "element-ui";
import { StatusCodes } from "http-status-codes";
import BizCode from "@/utils/BizCode";

import DelayLoadingService, {
  defaultDelay,
} from "@/components/LoadingService/DelayLoadingService";
// import router from '@/router'

//let isLoading;

// create an axios instance
export const request = axios.create({
  baseURL: "/api", // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    //isLoading = Loading.service({})
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error, "axios http 请求错误"); //http请求错误,输出下日志
    return Promise.reject(error);
  }
);

//业务逻辑异常的统一处理函数
const bizResponseErrorHandler = (response) => {
  const DEFAULT_ERROR_MSG = "服务器业务码异常，请稍等。。。";
  const DEFAULT_ERROR_MSG_TITLE = "系统提示！";

  Notification({
    title: DEFAULT_ERROR_MSG_TITLE,
    message: response?.data?.msg ?? DEFAULT_ERROR_MSG,
    type: "error",
  });
};

//服务器http错误的统一处理函数
const httpResponseErrorHandler = (response) => {
  const DEFAULT_ERROR_MSG = "服务器异常，请稍等。。。";
  const DEFAULT_ERROR_MSG_TITLE = "系统提示！";

  Notification({
    title: DEFAULT_ERROR_MSG_TITLE,
    message: response?.data?.msg ?? DEFAULT_ERROR_MSG,
    type: "error",
  });
};

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    //isLoading.close()
    //此处无需判断code,服务器返回httpStatusCode为2xx则视为成功才可能走此回调
    const contentType = response.headers["content-type"];
    if (contentType.includes("application/json")) {
      const res = response.data;
      //以下均为业务处理
      if (res?.code !== BizCode.OK) {
        //这里来做统一的业务异常拦截
        //只用这样调用的 await 方法才可以通过 try catch 异常进行捕获,否则则抛出
        //这里也可以选择将业务异常先提示再抛出
        if (BizCode.TOKEN_INVALID === res?.code) {
          // 不需要弹框
        } else {
          bizResponseErrorHandler(response); //暂时对所有的业务异常都进行提示
        }

        //选择是否抛给前端处理函数catch new Error有堆栈信息
        return Promise.reject(new Error(res.msg));
      }
      return res; //业务相应应该直接返回,因为在很多时候需要根据原始的res来做前端业务处理
    } else {
      //服务端返回了非json结构的数据,特别注意,(这意味着服务端返回了非标准返回格式)
      console.warn(
        response,
        "服务端返回了非json结构的数据,特别注意,(这意味着服务端返回了非标准返回格式)"
      );
      //(其他数据格式)
      return response;
    }
  },
  (error) => {
    //isLoading.close()
    //只有http请求StatusCodes返回非20*的值才会走此错误方法
    //走此方法说嘛一定是http请求返回出现了错误
    const httpCode = error?.response?.status;
    const bCode = error?.response?.data?.code;
    if (
      (httpCode === StatusCodes.FORBIDDEN && BizCode.TOKEN_INVALID === bCode)) {
      // 不要弹出框
    } else {
      httpResponseErrorHandler(error.response);
    }

    const httpStatusCode = error.response.status;

    // 未登录或登录失效(http状态码为未授权时)
    if (httpStatusCode === StatusCodes.UNAUTHORIZED) {
      //   router.push('/login')
      window.location.reload();
    }

    //http错误前端必定错误,所以一般情况下会将错误信息抛给前端调用的函数
    //error本身是一个Error对象否则new Error(error)或者作为内部异常双层嵌套
    return Promise.reject(error);
  }
);

//返回一个代理对象
//对loading问题单独处理
export default new Proxy(request, {
  // eslint-disable-next-line no-unused-vars
  get(target, propKey, receiver) {
    //拦截
    const origin = Reflect.get(target, propKey);
    if (
      (propKey === "get" ||
        propKey === "post" ||
        propKey === "put" ||
        propKey === "delete") &&
      typeof origin === "function"
    ) {
      return async (...args) => {
        //默认配置选项
        let defaults = {
          loading: true,
          delay: defaultDelay,
        };

        //loading选项的配置
        let loadingArg = args.find(
          (a) => a?.loading !== null && a?.loading !== undefined
        );

        //选项
        let opts = Object.assign(defaults, loadingArg);

        //删除loading配置对应的参数,没必要传输给后台
        if (loadingArg != null) {
          args.splice(args.indexOf(loadingArg), 1);
        }

        try {
          //如果需要显示loading则
          if (opts.loading) {
            DelayLoadingService.getService().openLoading();
          }

          let result = await origin(...args);

          //正确关闭loading
          if (opts.loading) {
            DelayLoadingService.getService().closeLoading(opts.delay);
          }

          //返回结果
          return result;
        } catch (err) {
          //错误,即可关闭loading
          if (opts.loading) {
            DelayLoadingService.getService().closeLoadingImmediate();
          }

          throw err;
        }
      };
    }

    return origin;
  },
});
