<template>
  <el-input-number
    v-model="schema.formData[schema.prop]"
    :placeholder="elForm.disabled?'':schema.placeholder"
  ></el-input-number>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  inject:['elForm'],
};
</script>
